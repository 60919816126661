/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Node, NodeFilters, NodeSortableFields } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { setJobWizardJobName } from '@/state/jobWizardStore';
import { fromSorter } from '@/utils/sort';
import { ConfigRevisionInfo } from '@/v1/components/ConfigureNodesJobWizard/ConfigureNodesJobWizardSelectConfigRevision';
import { NodesTableProps } from '@/v1/components/NodesTable/NodesTable';
import { useAssignConfigRevisionMutation } from '@/v1/utils/hooks/useAssignConfigRevisionMutation';
import { useGetConfigRevisionQuery } from '@/v1/utils/hooks/useGetConfigRevisionQuery';
import { useGetNodeConfigQuery } from '@/v1/utils/hooks/useGetNodeConfigQuery';
import { useNodesQuery } from '@/v1/utils/hooks/useNodesQuery';

import { ConfigureNodesJobWizardFooter } from './ConfigureNodesJobWizardFooter';
import { ConfigureNodesJobWizardReviewJobConfig } from './ConfigureNodesJobWizardReviewJobConfig';
import { ConfigureNodesJobWizardReviewJobNameCard } from './ConfigureNodesJobWizardReviewJobNameCard';
import { ConfigureNodesJobWizardReviewJobTable } from './ConfigureNodesJobWizardReviewJobTable';

export type ConfigureNodesJobWizardReviewJobProps = {
  tenantID: string;
  jobName?: string;
  selectedNodes: Node[];
  selectedConfigRevisionInfo: ConfigRevisionInfo;
  onSubmit: () => void;
};

export function ConfigureNodesJobWizardReviewJob({
  tenantID,
  jobName,
  selectedNodes,
  selectedConfigRevisionInfo,
  onSubmit,
}: ConfigureNodesJobWizardReviewJobProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const [message] = useMessage();
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState(jobName || `Configure ${selectedNodes.length} nodes`);
  const assignConfigRevision = useAssignConfigRevisionMutation(tenantID);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const predefinedSelectedIds = selectedNodes.map((node) => node.id);
  const [nodeFilters, setNodeFilters] = useState<Partial<NodeFilters>>({
    id: predefinedSelectedIds.join(','),
    isOperating: undefined,
  });
  const [nodeSort, setNodeSort] = useState<NodeSortableFields>([]);
  const [nodePage, setNodePage] = useState<PaginationParams>({ number: 1, size: 10 });

  const configQuery = useGetNodeConfigQuery(tenantID, selectedConfigRevisionInfo.configId);
  const revisionQuery = useGetConfigRevisionQuery(
    tenantID,
    selectedConfigRevisionInfo.configId,
    selectedConfigRevisionInfo.revisionNumber,
  );

  const selectedConfig = configQuery.data?.data;
  const selectedRevision = revisionQuery.data?.data;

  const nodesQuery = useNodesQuery(tenantID, nodeFilters, nodeSort, nodePage);

  const updateNodeQueryParams: NodesTableProps['onParamsChange'] = ({ filters, sort, paginationParams }) => {
    const queryFilters: Partial<NodeFilters> = filters || {};
    if (predefinedSelectedIds) queryFilters.id = predefinedSelectedIds.join(',');
    setNodeFilters({ ...queryFilters, isOperating: undefined });
    if (paginationParams) setNodePage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<NodeSortableFields, Node>(sort);
      setNodeSort(sortFields);
    }
  };

  const onPrevious = () => {
    navigate(`#select-config`);
  };

  const onNext = async () => {
    if (!name) {
      setError(true);
      return;
    }
    setSubmitting(true);

    try {
      await assignConfigRevision.mutateAsync({
        nodeIDs: selectedNodes.map((node) => node.id),
        configID: selectedConfigRevisionInfo.configId,
        revisionNumber: selectedConfigRevisionInfo.revisionNumber,
        jobName: name,
      });

      onSubmit();
    } catch (err) {
      message.error(t('unableToStartJob'));
    } finally {
      setSubmitting(false);
    }
  };

  const setJobName = (newName: string) => {
    setJobWizardJobName(newName);
    setName(newName);
  };

  return (
    <>
      <div className="configure-nodes-job-wizard-content">
        <div style={{ padding: '32px', paddingTop: 0, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <ConfigureNodesJobWizardReviewJobNameCard
            jobName={name}
            error={error}
            setError={setError}
            setJobName={setJobName}
          />
          <ConfigureNodesJobWizardReviewJobConfig
            tenantID={tenantID}
            selectedConfig={selectedConfig}
            selectedRevision={selectedRevision}
          />
          <ConfigureNodesJobWizardReviewJobTable
            tenantID={tenantID}
            nodesQuery={nodesQuery}
            onParamsChange={updateNodeQueryParams}
          />
        </div>
      </div>
      <ConfigureNodesJobWizardFooter
        onPrevious={onPrevious}
        onNext={onNext}
        onNextLabel={t('startJob')}
        onNextIcon={null}
        error={error}
        errorMessage={t('jobNameRequired')}
        loading={submitting}
      />
    </>
  );
}
