/*
 * Copyright Mimic Networks, Inc. 2024.
 */

export const UUID_NEXACORP = '0124e053-3580-7413-9db3-67f435decf1d';
export const UUID_NEXACORP_NODE = '550e8400-e29b-41d4-a716-446655440000';
export const UUID_NODE_CONFIG = 'ee0293bd-af0f-4cc6-9bf8-9fe6de3d3bed';

export type OsType = 'windows' | 'linux' | 'mac';
export const OS_TYPES = Object.freeze(['windows', 'linux', 'mac'] as const);

export type ArchType = 'x86_64' | 'arm64';
export const ARCH_TYPES = Object.freeze(['x86_64', 'arm64'] as const);
