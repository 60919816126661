/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { NODE_OPERATING_SYSTEM, TYPE_UUID, USER_INFO } from '../../types';

export const ConfigRevision = z
  .object({
    id: TYPE_UUID.openapi({
      'x-go-name': 'ID',
      example: '550e8400-e29b-41d4-a716-446655440000',
    }),
    revisionNumber: z.number().int().openapi({ example: 1 }),
    filename: z.string().openapi({ example: 'public-api-mimic.cue' }),
    configSchema: z.string().openapi({ example: 'v1.0.0' }),
    source: z.string().optional().openapi({ example: 'some cuelang code' }),
    description: z.string().openapi({ example: 'Revision message goes here' }),
    createdBy: USER_INFO,
    createdAt: z.date().openapi({ format: 'date-time' }),
    nodeConfigId: TYPE_UUID.optional(),
    nodeConfig: z
      .object({
        id: TYPE_UUID,
        name: z.string().openapi({ example: 'public-api-mimic' }),
      })
      .optional(),
  })
  .openapi('ConfigRevision');

export const NodeConfig = z
  .object({
    id: TYPE_UUID.openapi({
      'x-go-name': 'ID',
      example: '550e8400-e29b-41d4-a716-446655440000',
    }),
    name: z.string().openapi({ example: 'public-api-mimic' }),
    notes: z.string().optional().openapi({ example: 'Config for public apis' }),
    os: NODE_OPERATING_SYSTEM,
    dateCreated: z.date().openapi({ format: 'date-time' }),
    dateUpdated: z.date().openapi({ format: 'date-time' }),
    createdBy: USER_INFO,
    updatedBy: USER_INFO,
    latestConfigRevision: ConfigRevision,
    useCount: z.number().int().openapi({ example: 5 }),
    outdatedUseCount: z.number().int().openapi({ example: 2 }),
    latestRevisionNumber: z.number().int().openapi({ example: 1 }),
  })
  .openapi('NodeConfig');

export const NodeConfigResponseSchema = buildResponseSchema(
  'NodeConfigResponse',
  NodeConfig,
);

export const ConfigRevisionResponseSchema = buildResponseSchema(
  'ConfigRevisionResponse',
  ConfigRevision,
);

export const ConfigAssignmentSchema = z
  .object({
    jobName: z.string().optional().openapi({ example: 'job name' }),
    configRevisionNumber: z.number().int().optional(),
    nodeIds: z.array(TYPE_UUID),
  })
  .openapi('ConfigAssignmentRequest');

export const ConfigAssignmentResponseSchema = z
  .object({
    jobId: TYPE_UUID,
    configId: TYPE_UUID,
    configRevisionNumber: z.number().int(),
    nodeIds: z.array(TYPE_UUID),
  })
  .openapi('ConfigAssignmentResponse');

export const PatchNodeConfigSchema = z
  .object({
    name: z.string().optional(),
    notes: z.string().optional(),
  })
  .openapi('PatchNodeConfig');

export const NodeConfigFilterParamsSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().optional(),
    os: z.string().optional().openapi({ example: 'windows,linux' }),
  })
  .openapi('NodeConfigFilters');

export const NodeConfigSortableFields = sortable('NodeConfigSortableFields', [
  'name',
  'dateUpdated',
  'os',
  'useCount',
]);

export const ConfigRevisionFiltersParamsSchema = z
  .object({
    description: z.string().optional(),
  })
  .openapi('ConfigRevisionFilters');

export const ConfigRevisionSortableFields = sortable(
  'ConfigRevisionsSortableFields',
  [],
);

export const ConfigRevisionQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: ConfigRevisionFiltersParamsSchema.optional(),
    sort: ConfigRevisionSortableFields.optional(),
  }),
);

export const ConfigQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: NodeConfigFilterParamsSchema.optional(),
    sort: NodeConfigSortableFields.optional(),
  }),
);

export const PaginatedNodeConfigsResponseSchema = buildPaginatedResponseSchema(
  'PaginatedNodeConfigsResponse',
  NodeConfig,
  NodeConfigFilterParamsSchema,
  NodeConfigSortableFields,
);

export const PaginatedConfigRevisionResponseSchema =
  buildPaginatedResponseSchema(
    'PaginatedConfigRevisionResponse',
    ConfigRevision,
    ConfigRevisionFiltersParamsSchema,
    ConfigRevisionSortableFields,
  );

export const UploadNodeConfigSchema = z.object({
  configSchema: z.string().min(1),
  name: z.string(),
  os: NODE_OPERATING_SYSTEM,
  description: z.string(),
});

export const UploadConfigRevisionSchema = z.object({
  configSchema: z.string().min(1),
  description: z.string(),
});
