/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { PlusOutlined, StopOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ColumnsType } from 'antd/es/table';

import { User, UserFilters } from '@/client';
import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMessage } from '@/hooks/message';
import { canPerformAction } from '@/hooks/useAuthorization';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Container } from '@/primitives/Container';
import { Segmented, SegmentedValue } from '@/primitives/Segmented';
import { Space } from '@/primitives/Space';
import { UpdateParams } from '@/utils/params';
import { CountLabel } from '@/v1/components/CountLabel';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { GetUserListOptions } from '@/v1/utils/hooks/getUserListOptions';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

import { MemberActionDropdown } from './MemberActionDropdown';
import { Role } from './MemberTable';

enum UserState {
  REMOVED = 'removed',
  ACTIVE = 'active',
}

export type MembersListPageParams = {
  currentUser: User;
  activeOptions: GetUserListOptions;
  removedOptions: GetUserListOptions;
  filters: UserFilters;
  onInviteClick: () => void;
  onUpdateClick: (user: User) => void;
  onChangeRole: (user: User, role: Role) => void;
  onChangeRemoved: (user: User, removed: boolean) => void;
  onParamsChange: UpdateParams<User, Partial<UserFilters>>;
};

export function MembersListPage({
  currentUser,
  activeOptions,
  removedOptions,
  filters,
  onInviteClick,
  onUpdateClick,
  onChangeRole,
  onChangeRemoved,
  onParamsChange,
}: MembersListPageParams) {
  const { t } = useMimicTranslation('members');
  const [message] = useMessage();
  const queryClient = useQueryClient();
  const activeMembersQuery = useQuery(activeOptions);
  const removedMembersQuery = useQuery(removedOptions);

  useSubscribeToEvents<User>('user:created', (event) => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
    if (!event.data) return;

    message.success(
      t('feedback.userInvited', {
        user: `${event.data.displayName}`,
        email: event.data.email,
      }),
    );
  });

  const onChangeActiveFilter = (value: SegmentedValue) => {
    if (value === UserState.ACTIVE) {
      onParamsChange({ filters: { removed: false } });
    } else if (value === UserState.REMOVED) {
      onParamsChange({ filters: { removed: true } });
    }
  };

  const membersQuery = filters.removed ? removedMembersQuery : activeMembersQuery;
  const activeTotal = activeMembersQuery.data?.meta.page.totalItems || 0;
  const removedTotal = removedMembersQuery.data?.meta.page.totalItems || 0;
  const activeFilterValue = filters.removed ? UserState.REMOVED : UserState.ACTIVE;

  const removedUserColumns = getRemovedUsersColumns({
    currentUser,
    onChangeRemoved,
    t,
  });

  const activeColumns = getActiveUsersColumns({
    currentUser,
    onUpdateClick,
    onChangeRole,
    onChangeRemoved,
    t,
  });

  const columns = filters.removed ? removedUserColumns : activeColumns;

  return (
    <Container>
      <PageHeader title={t('title')} text={t('subtitle')}>
        <GuardedButton requiredRole="admin" type="primary" onClick={onInviteClick} dataTestId="invite-new-member">
          <PlusOutlined /> {t('newMember')}
        </GuardedButton>
      </PageHeader>
      <Segmented
        dataTestId="removed-switcher"
        defaultValue={UserState.ACTIVE}
        value={activeFilterValue}
        onChange={onChangeActiveFilter}
        options={[
          {
            label: (
              <CountLabel
                text="Active"
                count={activeTotal}
                loading={activeMembersQuery.isPending}
                data-testid="segment-active"
              />
            ),
            value: UserState.ACTIVE,
          },
          {
            label: (
              <CountLabel
                text="Removed"
                count={removedTotal}
                loading={removedMembersQuery.isPending}
                data-testid="segment-removed"
              />
            ),
            value: UserState.REMOVED,
          },
        ]}
      />
      <PaginatedTable
        dark={filters.removed}
        data-testid="member-table"
        rowKey={(tag) => tag.id}
        paginatedResource={membersQuery}
        columns={columns}
        onParamsChange={onParamsChange}
      />
    </Container>
  );
}

type GetActiveUserColumnsProps = Pick<
  MembersListPageParams,
  'currentUser' | 'onUpdateClick' | 'onChangeRole' | 'onChangeRemoved'
> & {
  t: MimicTranslationFunction<'members'>;
};

function getActiveUsersColumns({
  currentUser,
  onUpdateClick,
  onChangeRole,
  onChangeRemoved,
  t,
}: GetActiveUserColumnsProps): ColumnsType<User> {
  const activeUsersColumns: ColumnsType<User> = [
    {
      title: t('member'),
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role']) => t(`roles.${role}`),
    },
    {
      title: t('columns.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (dateCreated: User['dateCreated']) => (
        <Space minHeight="40px">
          <RelativeTime date={dateCreated} />
        </Space>
      ),
    },
  ];

  if (!canPerformAction('admin', currentUser)) return activeUsersColumns;

  return [
    ...activeUsersColumns,
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_, record) => {
        return (
          <MemberActionDropdown
            user={record}
            currentUser={currentUser}
            onUpdateClick={onUpdateClick}
            onChangeRole={onChangeRole}
            onChangeRemoved={onChangeRemoved}
          />
        );
      },
    },
  ];
}

type GetRemovedUserColumnsProps = Pick<MembersListPageParams, 'currentUser' | 'onChangeRemoved'> & {
  t: MimicTranslationFunction<'members'>;
};

function getRemovedUsersColumns({ currentUser, onChangeRemoved, t }: GetRemovedUserColumnsProps): ColumnsType<User> {
  const removedUsersColumns: ColumnsType<User> = [
    {
      title: t('member'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, record) => {
        if (record.removed) {
          return (
            <Space minHeight="40px">
              <StopOutlined />
              {record.displayName}
            </Space>
          );
        }
        return record.displayName;
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role']) => t(`roles.${role}`),
    },
    {
      title: t('columns.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (dateCreated: User['dateCreated']) => <RelativeTime date={dateCreated} />,
    },
  ];

  if (!canPerformAction('admin', currentUser)) return removedUsersColumns;

  return [
    ...removedUsersColumns,
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      align: 'right',
      render: (_, record) => {
        return (
          <Button type="default" onClick={() => onChangeRemoved(record, false)} dataTestId="restore-button">
            {t('actions.restore')}
          </Button>
        );
      },
    },
  ];
}
