/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { ApiError, DeleteTagResponse, Tag } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type DeleteTagMutationConfig = {
  onSuccess?: (data: void, variables: Tag) => void;
  onError?: (error: unknown, variables: Tag) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: Tag) => Promise<unknown> | void;
};

/**
 * Hook for deleting tag by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful tag deletion
 * @param config.onError - Called if tag deletion fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for deleting tag
 *
 * @example
 * ```tsx
  const { mutate } = useDeleteTagMutation({
 *   onSuccess: (data) => console.log('Tag Deleted:', data),
 *   onError: (error) => console.error('Tag Deletion failed:', error)
    },
  });

   * // Delete Tag
 * mutate({ tagId: '9fb763f7-33de-4969-a7a0-b808f4637318' });
 * ```
 */

export function useDeleteTagMutation({ onSuccess, onError, onSettled, onMutate }: DeleteTagMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<DeleteTagResponse, ApiError, Tag>({
    mutationFn: (tag: Tag) => {
      return mgmtPlaneAPIClient.tags.deleteTag({ tenantId: tenantId!, id: tag.id });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
