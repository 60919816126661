/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Form, Input } from 'antd';
import { FocusEventHandler, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { ApiError, ConfigRevision, Node, NodeConfig, NodeHallmark, NodeLifecycleEvent } from '@/client';
import { PageHeader } from '@/components/PageHeader';
import { useMessage } from '@/hooks/message';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Col } from '@/primitives/Col';
import { Collapse } from '@/primitives/Collapse';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { NodeIcon } from '@/primitives/Icons/NodeIcon';
import { Row } from '@/primitives/Row';
import { Skeleton } from '@/primitives/Skeleton';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';
import { BackButton } from '@/v1/components/Buttons/BackButton';
import { ConfigWizardOverlay } from '@/v1/components/ConfigWizardOverlay/ConfigWizardOverlay';
import { Hallmarks } from '@/v1/components/Node/Hallmarks';
import { IncomingData } from '@/v1/components/Node/IncomingData';
import { LifecycleEvents } from '@/v1/components/Node/LifecycleEvents';
import { NodeCredentials } from '@/v1/components/Node/NodeCredentials';
import { NodeHeartbeats } from '@/v1/components/Node/NodeHeartbeats';
import { NodeState } from '@/v1/components/Node/NodeState';
import { NodeViewPageCard } from '@/v1/components/Node/NodeViewPageCard';
import { NodeConfigState } from '@/v1/components/NodeConfigState/NodeConfigState';
import { TagsField } from '@/v1/components/TagsField';
import { getHallmarks } from '@/v1/utils/hooks/getHallmarks';
import { getNodeLifeCycleEvents } from '@/v1/utils/hooks/getNodeLifeCycleEvents';
import { getTagListOptions } from '@/v1/utils/hooks/getTagListOptions';
import { getUpdateNodeOperationalStateOptions } from '@/v1/utils/hooks/getUpdateNodeOperationalStateOptions';
import { useAddTagMutation } from '@/v1/utils/hooks/useAddTagMutation';
import { useAssignConfigRevisionMutation } from '@/v1/utils/hooks/useAssignConfigRevisionMutation';
import { useNewTagMutation } from '@/v1/utils/hooks/useNewTagMutation';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';
import { useRemoveTagMutation } from '@/v1/utils/hooks/useRemoveTagMutation';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';
import { useUpdateNodeMutation } from '@/v1/utils/hooks/useUpdateNodeMutation';

import { EditableNodeName } from './EditableNodeName';

export type NodeViewPageProps = {
  id: string;
  tenantID: string | undefined;
  nodeOptionsQuery: UseQueryResult<Node, ApiError>;
};

export function NodeViewPage({ id, tenantID, nodeOptionsQuery }: NodeViewPageProps) {
  const { t } = useMimicTranslation('node');
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
  const [nodeToShow, setNodeToShow] = useState<Node | undefined>();
  const [selectedNodeConfig, setSelectedNodeConfig] = useState<NodeConfig | undefined>();
  const [selectedConfigRevision, setSelectedConfigRevision] = useState<ConfigRevision | undefined>();
  const { isPending, isError, data, error, refetch } = nodeOptionsQuery;

  const assignConfigRevision = useAssignConfigRevisionMutation(tenantID!, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['node', data!.tenantId, data!.id] });
    },
  });
  const canUpdateNodeDescription = useAuthorization('editor');

  useSubscribeToEvents(
    [
      'node:updated',
      'node-config:assigned',
      'deflection:created',
      'node:operational-state-changed',
      'node:tag-added',
      'node-config:applied',
      'node:tag-deleted',
      'hallmark:created',
    ],
    (event) => {
      if (event.entityIds.includes(id)) return;
      queryClient.invalidateQueries({ queryKey: ['node', tenantID, id] });
    },
  );

  useSubscribeToEvents(['hallmark:created'], (event) => {
    if (!event.entityIds.includes(id)) return;

    queryClient.invalidateQueries({
      queryKey: ['hallmarks', tenantID, id],
    });
  });

  useSubscribeToEvents(
    ['node:credential-rotated', 'node:connectivity-updated', 'node:new-lifecycle-event'],
    (event) => {
      if (!event.entityIds.includes(id)) return;

      queryClient.invalidateQueries({ queryKey: ['node', tenantID, id] });

      queryClient.invalidateQueries({
        queryKey: ['node_lifecycle_events', tenantID, id],
      });
    },
  );

  useEffect(() => {
    if (isDescriptionFocused) return;
    form.setFieldValue('description', data?.description);
  }, [isDescriptionFocused, data, form]);

  const { paginationParams, updateQueryParams } = useQueryParams<NodeLifecycleEvent, unknown, string[]>({});
  const { paginationParams: hallmarksPaginationParams, updateQueryParams: updateHallmarksQueryParams } = useQueryParams<
    NodeHallmark,
    unknown,
    string[]
  >({}, 'hallmarks');

  const [message] = useMessage();
  const { mutateAsync: addTag } = useAddTagMutation(tenantID!);
  const { mutateAsync: removeTag } = useRemoveTagMutation(tenantID!);
  const { mutateAsync: newTagMutate } = useNewTagMutation(tenantID!);

  const mutationConfig = getUpdateNodeOperationalStateOptions(data!, tenantID!, queryClient);

  const { mutate } = useUpdateNodeMutation(tenantID!, data!, {
    onSuccess: () => {
      message.success(t('feedback.nodeUpdated'));
    },
    onError: () => {
      message.error(t('feedback.updateFailed'));
    },
  });

  if (isError) {
    if (error.status === 404) {
      return <Navigate to={`/tenants/${tenantID}/nodes`} replace />;
    }
    throw error;
  }

  if (!data) {
    return <Skeleton loading />;
  }
  const nodeTags = data.tags || [];

  const hallmarkOptions = getHallmarks(tenantID!, data.id, hallmarksPaginationParams);
  const nodeLifeCycleEventsOptions = getNodeLifeCycleEvents(tenantID!, data.id, paginationParams);

  const onDescriptionBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    setIsDescriptionFocused(false);
    if (event.target.value === null || event.target.value === undefined) return;
    const newDescription = event.target.value.trim();
    if (newDescription === data.description) return;
    mutate({ description: newDescription });
  };

  const onDescriptionFocus: FocusEventHandler<HTMLTextAreaElement> = () => {
    setIsDescriptionFocused(true);
  };

  const onNameChange = (newNodeName: string) => {
    mutate({ name: newNodeName });
  };

  if (!tenantID) return null;

  const onAddTag = async (tagName: string) => {
    await addTag({ nodeId: id, tagName });
    await refetch();
  };

  const onRemoveTag = async (tagName: string) => {
    await removeTag({ nodeId: id, tagName });
  };

  const onCreateTag = async (name: string) => {
    await newTagMutate({ name });
  };

  const onCloseConfigWizard = () => {
    setNodeToShow(undefined);
  };

  const onAssignConfigRevision = async (node: Node, configRevision: ConfigRevision) => {
    const currentConfigInUse =
      node.appliedRevisionState?.nodeConfig?.id === configRevision.nodeConfigId &&
      node.appliedRevisionState?.configRevision?.revisionNumber === configRevision.revisionNumber;

    if (currentConfigInUse) {
      message.error(t('feedback.currentConfigInUse'));
      return;
    }

    try {
      await assignConfigRevision.mutateAsync({
        nodeIDs: [node.id],
        configID: configRevision.nodeConfigId!,
        revisionNumber: configRevision.revisionNumber,
      });

      setNodeToShow(undefined);
    } catch (err) {
      message.error(t('feedback.assignNodeConfigError'));
      Sentry.captureException(err);
    }
  };

  const operatingSystemName = data.operatingSystem || '-';
  const operatingSystemVersion =
    data.systemProfile && data.systemProfile['os.version'] ? data.systemProfile['os.version'] : '';
  const operatingSystemDescription = `${operatingSystemName} ${operatingSystemVersion}`.trim();

  return (
    <Container>
      <BackButton to={`/tenants/${tenantID}/nodes`} />
      <PageHeader
        icon={<NodeIcon />}
        title={
          <Skeleton loading={isPending} title width="xxl">
            <Text mono>{data.systemProfile?.['host.name'] || t('hostNameNotAvailable')}</Text>
          </Skeleton>
        }
        text={
          <Space dir="vertical">
            <Row>
              <Skeleton loading={isPending} title width="xl">
                <Container style={{ width: '40vw' }}>
                  <EditableNodeName name={data.name} onNameChange={onNameChange} />
                </Container>
              </Skeleton>
            </Row>
            <TagsField
              selectedTags={nodeTags}
              getTagListOptions={getTagListOptions}
              tenantID={tenantID}
              onAddTag={onAddTag}
              onRemoveTag={onRemoveTag}
              onCreateTag={onCreateTag}
            />
          </Space>
        }
        level={4}
        fontWeight="300"
      />
      <Row gutter={16}>
        <Col span={24}>
          <NodeState isLoading={isPending} node={data} mutationConfig={mutationConfig} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Collapse
            data-testid="hallmark-collapse"
            items={[
              {
                key: '1',
                label: (
                  <div
                    style={{ fontSize: '1.125rem', fontWeight: 700, backgroundColor: tokens.color.surface.surfaceHigh }}
                  >
                    {t('hallmarks')}
                  </div>
                ),
                children: (
                  <div data-testid="hallmarks-section">
                    <Hallmarks options={hallmarkOptions} onParamsChange={updateHallmarksQueryParams} />
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Flex vertical>
            <NodeViewPageCard title={t('lifecycle_events')} dataTestId="lifecycle-events-section">
              <LifecycleEvents options={nodeLifeCycleEventsOptions} onParamsChange={updateQueryParams} />
            </NodeViewPageCard>
            <NodeViewPageCard title={t('node_description')}>
              <Form form={form} initialValues={data} layout="vertical">
                <Form.Item name="description">
                  <Input.TextArea
                    allowClear
                    rows={4}
                    onBlur={onDescriptionBlur}
                    onFocus={onDescriptionFocus}
                    placeholder={t('node_description_placeholder')}
                    count={{ show: true, max: 280 }}
                    maxLength={280}
                    disabled={!canUpdateNodeDescription}
                    style={{
                      backgroundColor: tokens.color.surface.surfaceHigh,
                    }}
                  />
                </Form.Item>
              </Form>
            </NodeViewPageCard>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <NodeConfigState
              node={data}
              assignNewNodeConfig={() => setNodeToShow(data)}
              assignNewConfigRevision={() => {
                setSelectedNodeConfig(data.appliedRevisionState?.nodeConfig as unknown as NodeConfig);

                setSelectedConfigRevision({
                  ...data.appliedRevisionState?.configRevision,
                  nodeConfigId: data.appliedRevisionState?.nodeConfig?.id,
                } as ConfigRevision);

                setNodeToShow(data);
              }}
              getNodeConfigPath={(node: Node) =>
                `/tenants/${tenantID}/node-configs/${node.appliedRevisionState?.nodeConfig?.id}`
              }
              getNodeConfigRevisionPath={(node: Node) =>
                `/tenants/${tenantID}/node-configs/${node.appliedRevisionState?.nodeConfig?.id}/revisions/${node.appliedRevisionState?.configRevision?.revisionNumber}`
              }
            />
            <NodeViewPageCard title={t('server_configuration')}>
              <Form layout="vertical">
                <Row>
                  <Col span={12}>
                    <IncomingData
                      label={t('ip_address')}
                      loading={isPending}
                      text={data.systemProfile?.['host.ip']}
                      mono
                      copyable
                    />
                  </Col>
                  <Col span={12}>
                    <IncomingData
                      dataTestId="node-operating-system"
                      label={t('os')}
                      loading={isPending}
                      text={operatingSystemDescription}
                      mono
                      copyable
                    />
                  </Col>
                </Row>
              </Form>
            </NodeViewPageCard>
            <NodeCredentials node={data} isPending={isPending} />
            <NodeHeartbeats data={data} isPending={isPending} />
          </Flex>
        </Col>
      </Row>
      {nodeToShow ? (
        <ConfigWizardOverlay
          node={nodeToShow}
          defaultNodeConfig={selectedNodeConfig}
          defaultConfigRevision={selectedConfigRevision}
          onCancel={onCloseConfigWizard}
          onAssign={onAssignConfigRevision}
        />
      ) : null}
    </Container>
  );
}
