/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { NumberOutlined } from '@ant-design/icons';

import { ConfigRevision } from '@/client';
import { GuardedButton } from '@/components/GuardedButton';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

export type SelectedConfigRevisionCardProps = {
  configRevision: ConfigRevision;
  onChangeClick: () => void;
};

export function SelectedConfigRevisionCard({ configRevision, onChangeClick }: SelectedConfigRevisionCardProps) {
  const { t } = useMimicTranslation('configWizardOverlay');

  return (
    <Card dataTestId="config-wizard-content-node-config" padding="sm" bordered>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '0.8em' }}>
        <Text type="subtle" strong size="md">
          {t('revision')}
        </Text>
        <Flex vertical full gap="xxs">
          <Flex full justify="space-between">
            <Title style={{ fontWeight: 500 }} level={4}>
              <NumberOutlined /> {configRevision?.revisionNumber} · {configRevision?.description}
            </Title>
            <GuardedButton
              requiredRole="superadmin"
              dataTestId="config-wizard-config-revision-change"
              type="link"
              onClick={onChangeClick}
            >
              {t('change')}
            </GuardedButton>
          </Flex>
          <Text copyable mono size="md" type="subtle">
            {configRevision?.id}
          </Text>
        </Flex>
      </div>
    </Card>
  );
}
