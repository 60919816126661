/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import { useState } from 'react';

import { ApiError } from '@/client';
import { validationErrors } from '@/components/Forms/validationErrors';
import { useMessage } from '@/hooks/message';
import { useNewTagMutation } from '@/hooks/mutation/useNewTagMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { TagForm, TagFormFields } from '@/pages/Tags/TagForm';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { getValidationMessage } from '@/utils/errors';

export type NewTagDialogProps = {
  setNewTagOpen: (open: boolean) => void;
};

export function NewTagDialog({ setNewTagOpen }: NewTagDialogProps) {
  const { t } = useMimicTranslation('tags');
  const [form] = Form.useForm<TagFormFields>();
  const [message] = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const { mutate: createTagMutation } = useNewTagMutation({
    onError: (error, tag) => {
      message.error(t('newTagCreatedError', tag));
      if (error instanceof ApiError) {
        const newMessage = getValidationMessage(error, 'name');
        form.setFields(validationErrors<TagFormFields>({ name: newMessage }));
      }
      setIsLoading(false);
    },
    onSuccess: (_, tag) => {
      message.success(t('newTagCreated', tag));
      setNewTagOpen(false);
      setIsLoading(false);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const onTagCreate = (tagName: string) => {
    createTagMutation({ name: tagName });
  };

  const onFormFinish = () => {
    const name = form.getFieldValue('name');
    onTagCreate(name);
  };

  const okButton = (
    <Button
      loading={isLoading}
      type="primary"
      htmlType="submit"
      dataTestId="add-tag-submit"
      style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
    >
      {t('addTag.submitButton')}
    </Button>
  );
  return (
    <Card padding="md">
      <TagForm form={form} loading={isLoading} onFinish={onFormFinish} okButton={okButton} horizontal />
    </Card>
  );
}
