/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';

import { ContentContainer } from '@/components/ContentContainer';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Skeleton } from '@/primitives/Skeleton';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { BackButton } from '@/v1/components/Buttons/BackButton';
import { NodeConfigurationRevisionSourceCode } from '@/v1/components/NodeConfigurationRevisionSourceCode';
import { useGetConfigRevisionQuery } from '@/v1/utils/hooks/useGetConfigRevisionQuery';
import { useGetNodeConfigQuery } from '@/v1/utils/hooks/useGetNodeConfigQuery';

export function NodeConfigurationRevisionSourceCodeRoute() {
  const { nodeConfigId } = useParams<'nodeConfigId'>();
  const { configRevisionNumber } = useParams<'configRevisionNumber'>();
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { data: nodeConfigResponse, isLoading: isLoadingNodeConfig } = useGetNodeConfigQuery(tenantId!, nodeConfigId!);
  const { data: configRevisionResponse, isLoading: isLoadingConfigRevisions } = useGetConfigRevisionQuery(
    tenantId!,
    nodeConfigId!,
    Number(configRevisionNumber),
  );

  const getNodeConfigurationPath = () => `/tenants/${tenantId}/node-configs/${nodeConfigId}`;

  if (isLoadingNodeConfig || !nodeConfigResponse) return <Skeleton loading />;
  if (isLoadingConfigRevisions || !configRevisionResponse) return <Skeleton loading />;

  return (
    <ContentContainer width="100%">
      <Flex vertical gap="md">
        <Container>
          <BackButton to={`/tenants/${tenantId}/node-configs/${nodeConfigId}`} />
        </Container>
        <NodeConfigurationRevisionSourceCode
          nodeConfiguration={nodeConfigResponse.data}
          nodeConfigRevision={configRevisionResponse.data}
          getNodeConfigurationPath={getNodeConfigurationPath}
        />
      </Flex>
    </ContentContainer>
  );
}
