import { User } from '@/client';
import { useAuthorization } from '@/hooks/useAuthorization';
import { Link, LinkProps } from '@/primitives/Link';
import { Text } from '@/primitives/Text';

export type GuardedLinkProps = LinkProps & {
  requiredRole: User['role'];
};

export function GuardedLink(props: GuardedLinkProps) {
  const { requiredRole, children, ...rest } = props;
  const linkEnabled = useAuthorization(requiredRole);

  if (!linkEnabled) {
    return <Text type="default">{children}</Text>;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link {...rest}>{children}</Link>;
}
