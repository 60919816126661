/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  ARCH_TYPES,
  OS_TYPES,
  UUID_NEXACORP,
  UUID_NEXACORP_NODE,
  UUID_NODE_CONFIG,
} from './constants';

export const TYPE_UUID = z
  .string()
  .uuid()
  .regex(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
  );

export const OPENAPI_TENANT_ID = TYPE_UUID.openapi({ example: UUID_NEXACORP });

export const OPENAPI_NODE_ID = TYPE_UUID.openapi({
  example: UUID_NEXACORP_NODE,
});

export const OPENAPI_NODE_CONFIG_ID = TYPE_UUID.openapi({
  example: UUID_NODE_CONFIG,
});

export const OPENAPI_OS_TYPE = z
  .enum(OS_TYPES)
  .openapi('OsType', { example: 'windows' });

export const OPENAPI_ARCH_TYPE = z
  .enum(ARCH_TYPES)
  .openapi('ArchType', { example: 'x86_64' });

export const OPENAPI_NODE_CONNECTIVITY_STATE = z
  .enum(['connected', 'disconnected', 'degraded'])
  .openapi('ConnectivityState', { example: 'connected' });

export const OPENAPI_NODE_HEARTBEAT_STATE = z
  .enum(['online', 'offline'])
  .openapi('HeartbeatConnectivityState', { example: 'online' });

export const OPENAPI_NODE_OPERATIONAL_STATE = z
  .enum(['active', 'idle'])
  .openapi('OperationalState', { example: 'active' });

export const OPENAPI_NODE_CONFIG_STATUS = z
  .enum(['pending', 'applied', 'rejected'])
  .openapi('ConfigStatus', { example: 'pending' });

export const NODE_OPERATING_SYSTEM = z
  .enum(['windows', 'linux', 'not_applicable'])
  .openapi('NodeOperatingSystem', { example: 'windows' });

export const NODE_ARCHITECTURE = z
  .enum(['amd64', 'arm64', 'x86_64'])
  .openapi('NodeArchitecture', { example: 'amd64' });

export const NODE_PACKAGE_TYPE = z
  .enum(['deb', 'rpm', 'msi'])
  .openapi('NodePackageType', { example: 'msi' });

export const LINUX_OS = z.enum(['linux']);
export const LINUX_NODE_ARCHITECTURE = z.enum(['amd64', 'arm64']);

export const WINDOWS_OS = z.enum(['windows']);
export const WINDOWS_NODE_ARCHITECTURE = z.enum(['x86_64']);

export const LINUX_NODE = {
  targetOs: LINUX_OS,
  architecture: LINUX_NODE_ARCHITECTURE,
};

export const WINDOWS_NODE = {
  targetOs: WINDOWS_OS,
  architecture: WINDOWS_NODE_ARCHITECTURE,
};

export const NODE_ARCHITECTURE_COMBO = z.discriminatedUnion('targetOs', [
  z.object(LINUX_NODE),
  z.object(WINDOWS_NODE),
]);

export const USER_INFO = z
  .object({
    id: TYPE_UUID,
    displayName: z.string().openapi({ example: 'John Doe' }),
    email: z.string().email().openapi({ example: 'john@somewhere.com' }),
  })
  .openapi('UserInfo');
