/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { usePaginatedTagsQuery } from '@/hooks/query/usePaginatedTagsQuery';
import { TagsList } from '@/pages/Tags/TagsList';

export function TagsPage() {
  const tagsQuery = usePaginatedTagsQuery({ type: 'queryParams' });

  return (
    <QueryWrapper queryResult={tagsQuery}>
      {(tagsData) => (
        <ContentContainer>
          <TagsList tagsData={tagsData} onParamsChange={tagsQuery.updateQueryParams} />
        </ContentContainer>
      )}
    </QueryWrapper>
  );
}
