/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { NodeResponse, PatchNode } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateNodeMutationConfig = {
  nodeId: string;
  onSuccess?: (data: NodeResponse, variables: PatchNode) => void;
  onError: (error: unknown, variables: PatchNode) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

/**
 * Hook for updating node data through the management plane API.
 *
 * @param config.nodeId - ID of the node to update
 * @param config.onSuccess - Called after successful node update
 * @param config.onError - Called if update fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for updating node data
 *
 * @example
 * ```tsx
 * const { mutate } = useUpdateNodeMutation({
 *   nodeId: '123',
 *   onSuccess: (data) => console.log('Node updated:', data),
 *   onError: (error) => console.error('Update failed:', error)
 * });
 *
 * // Update node
 * mutate({ description: 'Updated description' });
 * ```
 */
export function useUpdateNodeMutation({ nodeId, onSuccess, onError, onSettled }: UpdateNodeMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<NodeResponse, unknown, PatchNode>({
    mutationFn: (patchNodeDto: PatchNode) => {
      return mgmtPlaneAPIClient.nodes.updateNode({ tenantId: tenantId!, id: nodeId, requestBody: patchNodeDto });
    },
    onSuccess,
    onError,
    onSettled,
  });

  return { mutate, isError, error };
}
