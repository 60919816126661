/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  FieldTimeOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';

import { JobStatus } from '@/client';
import { GuardedButton } from '@/components/GuardedButton';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { Tooltip } from '@/primitives/Tooltip';
import { tokens } from '@/theme';

export type JobNodeStatusProps = {
  status: JobStatus;
  showWarningsButton: boolean;
  openWarningsModal: () => void;
  openErrorsModal: () => void;
};

const fontSize = '1.25rem';

function getJobNodeStatusIcon(
  status: JobStatus,
  t: MimicTranslationFunction<'jobs'>,
  showWarningsButton: boolean,
  openWarningsModal: () => void,
  openErrorsModal: () => void,
) {
  if (status === 'success') {
    return (
      <Flex align="center" gap="xs">
        <CheckCircleFilled style={{ fontSize, color: tokens.color.success.successDefault }} />
        <Text type="default" size="md" style={{ lineHeight: '35px' }}>
          {t('jobNodes.configured')}
        </Text>
      </Flex>
    );
  }
  if (status === 'error') {
    return (
      <Flex align="center" gap="xs">
        <ExclamationCircleFilled style={{ fontSize, color: tokens.color.error.error }} />
        <Text type="default" size="md" style={{ lineHeight: '35px' }}>
          {t('jobNodes.rejected')}
          <Tooltip placement="top" title={t('jobNodeIssuesModal.errors')}>
            <GuardedButton
              requiredRole="superadmin"
              style={{ padding: '0 0.4em', fontSize: '12px' }}
              type="link"
              onClick={openErrorsModal}
            >
              {t('jobNodeIssuesModal.errors')}
            </GuardedButton>
          </Tooltip>
          {showWarningsButton && (
            <Tooltip placement="top" title={t('jobNodeIssuesModal.warnings')}>
              <GuardedButton
                requiredRole="superadmin"
                style={{ padding: '0 0.4em', fontSize: '12px' }}
                type="link"
                onClick={openWarningsModal}
              >
                {t('jobNodeIssuesModal.warnings')}
              </GuardedButton>
            </Tooltip>
          )}
        </Text>
      </Flex>
    );
  }
  if (status === 'warning') {
    return (
      <Flex align="center" gap="xs">
        <WarningFilled style={{ fontSize, color: tokens.color.yellow.yellow06 }} />
        <Text type="default" size="md" style={{ lineHeight: '35px' }}>
          {t('jobNodes.warning')}
          <Tooltip placement="top" title={t('jobNodeIssuesModal.warnings')}>
            <GuardedButton
              requiredRole="superadmin"
              style={{ padding: '0 0.4em', fontSize: '12px' }}
              type="link"
              onClick={openWarningsModal}
            >
              {t('jobNodeIssuesModal.warnings')}
            </GuardedButton>
          </Tooltip>
        </Text>
      </Flex>
    );
  }
  if (status === 'running') {
    return (
      <Flex align="center" gap="xs">
        <LoadingOutlined />
        <Text type="default" size="md" style={{ lineHeight: '35px' }}>
          {t('jobNodes.processing')}
        </Text>
      </Flex>
    );
  }
  if (status === 'timeout') {
    return <FieldTimeOutlined style={{ fontSize }} />;
  }

  return <QuestionCircleOutlined style={{ fontSize }} />;
}

export function JobNodeStatus({ status, openWarningsModal, openErrorsModal, showWarningsButton }: JobNodeStatusProps) {
  const { t } = useMimicTranslation('jobs');
  const statusIcon = getJobNodeStatusIcon(status, t, showWarningsButton, openWarningsModal, openErrorsModal);

  return (
    <Flex align="center" gap="xs">
      {statusIcon}
    </Flex>
  );
}
