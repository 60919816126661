/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { usePaginatedNodeConfigsQuery } from '@/hooks/query/usePaginatedNodeConfigsQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function NodeList() {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { data, sort, updateQueryParams } = usePaginatedNodeConfigsQuery({
    params: {
      filters: {
        os: 'linux',
      },
      sort: ['-name'],
    },
    type: 'queryParams',
  });

  const filterByOS = (os?: 'windows' | 'linux') => {
    updateQueryParams({
      filters: {
        os,
      },
    });
  };

  const toggleSort = () => {
    updateQueryParams({
      sort: sort?.includes('+name') ? [{ field: 'name', order: 'descend' }] : [{ field: 'name', order: 'ascend' }],
    });
  };

  if (!tenantID) return null;

  return (
    <div>
      <pre>
        {JSON.stringify(
          data?.data?.map((item) => ({ id: item.id, name: item.name })),
          null,
          2,
        )}
      </pre>
      <br />
      <p>
        <button type="button" onClick={() => filterByOS('windows')}>
          windows
        </button>
        <button type="button" onClick={() => filterByOS('linux')}>
          linux
        </button>
        <button type="button" onClick={() => filterByOS()}>
          clear
        </button>
      </p>
      <p>
        <button type="button" onClick={() => toggleSort()}>
          toggleSort
        </button>
      </p>
    </div>
  );
}
