/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { EditOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { SetStateAction } from 'react';

import { Tag } from '@/client';
import MimicHighlighter from '@/components/MimicHighlighter';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { TagActionDropdown } from '@/pages/Tags/TagActionDropdown';
import { Button } from '@/primitives/Button';
import { Space } from '@/primitives/Space';
import { Tag as TagComp } from '@/primitives/Tag';

export function useTagListColumns(
  searchText: string,
  editTag: React.Dispatch<SetStateAction<Tag | undefined>>,
  deleteTag: React.Dispatch<SetStateAction<Tag | undefined>>,
): ColumnsType<Tag> {
  const { t } = useMimicTranslation('tags');
  const canEditTags = useAuthorization('editor');
  const columns: ColumnsType<Tag> = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (name) => {
        return (
          <TagComp>
            <MimicHighlighter searchText={searchText} text={name} />
          </TagComp>
        );
      },
    },
    {
      title: t('useCount'),
      dataIndex: 'useCount',
      key: 'useCount',
      sorter: true,
      align: 'right',
    },
  ];

  if (!canEditTags) return columns;

  return [
    ...columns,
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      width: 64,
      render: (_, record) => {
        return (
          <Space compact>
            <Button dataTestId="edit-tag-name" type="default" onClick={() => editTag(record)}>
              <EditOutlined /> {t('actions.editTagButton')}
            </Button>
            <TagActionDropdown tag={record} onDeleteTag={() => deleteTag(record)} />
          </Space>
        );
      },
    },
  ];
}
