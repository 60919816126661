/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { NodeDetailsPage } from '@/pages/NodeDetailsPage';
import { HTTPError } from '@/v1/components/HTTPError';

export function NodeDetailsRoute() {
  const { nodeId } = useParams<'nodeId'>();

  if (!nodeId || !uuidValidate(nodeId)) {
    return <HTTPError httpStatus="404" />;
  }

  return <NodeDetailsPage nodeId={nodeId} />;
}
