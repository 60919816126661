import { User } from '@/client';
import { useAuthorization } from '@/hooks/useAuthorization';
import { ButtonProps, Button } from '@/primitives/Button';

export type GuardedButtonProps = ButtonProps & {
  requiredRole: User['role'];
};

export function GuardedButton(props: GuardedButtonProps) {
  const { requiredRole, ...rest } = props;
  const buttonEnabled = useAuthorization(requiredRole);

  if (!buttonEnabled) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button {...rest} />;
}
