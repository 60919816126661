/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import type { ColumnType, ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { Node, NodeFilters, Tag } from '@/client';
import { GuardedButton } from '@/components/GuardedButton';
import MimicHighlighter from '@/components/MimicHighlighter';
import { MimicTranslationFunction } from '@/hooks/useMimicTranslation';
import { OsIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Tooltip } from '@/primitives/Tooltip';
import { GuardedLink } from '@/v1/components/GuardedLink';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { ConnectivityTime } from '@/v1/components/States/ConnectivityTime';
import { OperationalState } from '@/v1/components/States/OperationalState';
import { TagsList } from '@/v1/components/TagsList';

export type NodesColumns =
  | 'hostname'
  | 'name'
  | 'tags'
  | 'appliedOperationalState'
  | 'connectivityState'
  | 'operatingSystem'
  | 'lastSeen'
  | 'dateCreated'
  | 'assignConfiguration'
  | 'appliedConfiguration';

export function getNodesColumns(
  columns: NodesColumns[],
  tenantID: string,
  t: MimicTranslationFunction<'nodes'>,
  getNodeConfigurationPath: (nodeConfigId: string) => string,
  tagsList?: Tag[] | undefined,
  filters?: NodeFilters,
  onAssignConfig?: (node: Node) => void,
  hideFilters?: boolean,
): ColumnsType<Node> {
  const allColumns: ColumnType<Node>[] = [
    {
      title: <span data-testid="hostName">{t('table.header.host_name')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, record: Node) => {
        const hostName = hostname || t('table.notAvailable');
        return (
          <Link
            to={`/tenants/${tenantID}/nodes/${record.id}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <MimicHighlighter searchText={filters?.hostname} text={hostName} />
          </Link>
        );
      },
      filterMode: !hideFilters ? 'menu' : undefined,
    },
    {
      title: <span data-testid="nickname">{t('table.header.nickname')}</span>,
      dataIndex: 'name',
      sorter: true,
      filterMode: !hideFilters ? 'menu' : undefined,
      render: (name: string) => <MimicHighlighter searchText={filters?.name} text={name} />,
    },
    {
      title: <span data-testid="tags">{t('table.header.tags')}</span>,
      dataIndex: 'tags',
      filters: !hideFilters
        ? tagsList?.map((tag) => {
            return { text: tag.name, value: tag.name };
          })
        : undefined,
      filterMode: !hideFilters ? 'menu' : undefined,
      filterSearch: hideFilters && true,
      width: '200px',
      render: (tags: string[]) => {
        return <TagsList tags={tags} />;
      },
    },
    {
      title: <span data-testid="operationalState">{t('table.header.operationalState')}</span>,
      dataIndex: 'appliedOperationalState',
      sorter: true,
      render: (_, node) => <OperationalState state={node.appliedRevisionState!.operationalState} />,
      width: '175px',
    },
    {
      title: <span data-testid="os">{t('table.header.os')}</span>,
      dataIndex: 'operatingSystem',
      sorter: true,
      filters: !hideFilters
        ? [
            { text: 'Windows', value: 'windows' },
            { text: 'Linux', value: 'linux' },
          ]
        : undefined,
      render: (operatingSystem: Node['operatingSystem'], node: Node) => {
        const osTooltip = operatingSystem || '';
        const osVersionTooltip = node.systemProfile?.['os.version'] || '';
        const tooltip = osTooltip || osVersionTooltip ? `${osTooltip} ${osVersionTooltip}` : '';
        return (
          <Tooltip title={tooltip.trim()} placement="right">
            <div data-testid="operating-system-icon" aria-label={tooltip.trim() || '-'}>
              <OsIcon name={operatingSystem} />
            </div>
          </Tooltip>
        );
      },
      width: '70px',
    },
    {
      title: <span data-testid="connectivity">{t('table.header.connectivity')}</span>,
      dataIndex: 'lastSeen',
      sorter: true,
      render: (_, record: Node) => {
        return (
          <ConnectivityTime
            state={record.connectivityState}
            lastSeen={record.lastSeen}
            heartbeatStates={record.heartbeatStates}
          />
        );
      },
      width: '200px',
    },
    {
      title: <span data-testid="dateCreated">{t('table.header.dateCreated')}</span>,
      dataIndex: 'dateCreated',
      sorter: true,
      key: 'dateCreated',
      render: (dateCreated: Node['dateCreated']) => <RelativeTime date={dateCreated} />,
    },
    {
      title: <span data-testid="dateCreated">{t('table.header.configuration')}</span>,
      dataIndex: 'appliedConfiguration',
      filterMode: !hideFilters ? 'menu' : undefined,
      sorter: true,
      width: '250px',
      render: (_, node: Node) => {
        const nodeConfigName = node.appliedRevisionState?.nodeConfig?.name;
        const configRevisionNumber = node.appliedRevisionState?.configRevision?.revisionNumber;
        if (!node.appliedRevisionState?.nodeConfig || !nodeConfigName || !configRevisionNumber)
          return t('table.notAvailable');
        const updatedBy = node.appliedRevisionState.assignedBy?.displayName;
        const updatedAt = dayjs(node.appliedRevisionState.statusDate).utc().format('MM/D/YYYY HH:mm:ss');
        const appliedConfiguration = `${nodeConfigName} · #${configRevisionNumber}`;
        return (
          <GuardedLink
            dataTestId="configuration-path"
            to={getNodeConfigurationPath(node.appliedRevisionState.nodeConfig.id)}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            requiredRole="editor"
          >
            <Tooltip title={`${t('table.header.configurationUpdated', { updatedBy, updatedAt })}`} placement="top">
              <MimicHighlighter searchText={filters?.hostname} text={appliedConfiguration} />{' '}
            </Tooltip>
          </GuardedLink>
        );
      },
    },
    {
      title: '',
      width: '210px',
      dataIndex: 'assignConfiguration',
      render: (_, node: Node) => {
        if (!onAssignConfig) return null;
        const configurationInProcess = node.requestedRevisionState?.status === 'pending';
        const label = configurationInProcess ? t('table.configurationInProgress') : t('table.assignConfiguration');
        const os = node.operatingSystem || 'unknown-os';
        return (
          <GuardedButton
            dataTestId={`assign-configuration-to-${os}-node-btn`}
            loading={configurationInProcess}
            type="default"
            onClick={() => onAssignConfig(node)}
            requiredRole="editor"
          >
            {label}
          </GuardedButton>
        );
      },
    },
  ];

  return allColumns.filter(({ dataIndex }) => columns.includes(dataIndex as NodesColumns));
}
