/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { JobNode, JobNodeFilters, JobNodeSortableFields } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { Skeleton } from '@/primitives/Skeleton';
import { defineTypes } from '@/utils/filters';
import { HTTPError } from '@/v1/components/HTTPError';
import { JobDetails } from '@/v1/components/JobDetails/JobDetails';
import { useGetJobQuery } from '@/v1/utils/hooks/useGetJobQuery';
import { useJobNodesQuery } from '@/v1/utils/hooks/useJobNodesQuery';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

const JobNodesFilterTypes = defineTypes<JobNodeFilters>({});

export function JobDetailsRoute() {
  const params = useParams<'jobId' | 'tenantID'>();
  const queryClient = useQueryClient();
  const { filters, sort, paginationParams, updateQueryParams } = useQueryParams<
    JobNode,
    JobNodeFilters,
    JobNodeSortableFields
  >(JobNodesFilterTypes);

  const {
    data: jobResponse,
    isPending,
    error,
    isError,
  } = useGetJobQuery({
    tenantId: params.tenantID,
    jobId: params.jobId,
  });

  const jobNodesQuery = useJobNodesQuery({
    jobId: params.jobId,
    tenantId: params.tenantID,
    filters,
    sort,
    settings: paginationParams,
  });

  useSubscribeToEvents(['job:updated', 'job:completed', 'job:timedout'], () => {
    queryClient.invalidateQueries({ queryKey: ['jobs', params.tenantID, params.jobId] });
    queryClient.invalidateQueries({ queryKey: ['job-nodes', params.jobId] });
  });

  if (!params.tenantID || !params.jobId) {
    Sentry.captureException(
      new Error('Missing tenantId/jobId. Are we rendering JobDetailsRoute in a route that provides these params?'),
    );

    return <HTTPError httpStatus="500" />;
  }

  if (isPending) return <Skeleton loading={isPending} title width="xxl" />;

  if (isError) {
    switch (error?.status) {
      case 403:
        return <HTTPError httpStatus="403" />;
      case 404:
        return <HTTPError httpStatus="404" />;
      default:
        Sentry.captureException(error);
        return <HTTPError httpStatus="500" />;
    }
  }

  if (!jobResponse?.data) {
    Sentry.captureException(new Error('Job not found while HTTP status is 2xx?'));
    return <HTTPError httpStatus="500" />;
  }

  return (
    <ContentContainer width="100%">
      <JobDetails
        tenantID={params.tenantID}
        job={jobResponse.data}
        jobNodesQuery={jobNodesQuery}
        onJobNodesParamsChange={updateQueryParams}
      />
    </ContentContainer>
  );
}
