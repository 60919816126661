/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { ConfigRevision, Node, NodeFilters, NodeSortableFields } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { ConfigWizardOverlay } from '@/v1/components/ConfigWizardOverlay/ConfigWizardOverlay';
import { getTagListOptions } from '@/v1/utils/hooks/getTagListOptions';
import { useAssignConfigRevisionMutation } from '@/v1/utils/hooks/useAssignConfigRevisionMutation';
import { NodesFilterTypes, useNodesQuery } from '@/v1/utils/hooks/useNodesQuery';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';

import { NodeListPage } from './NodeListPage';

export function NodeListRoute() {
  const [message] = useMessage();
  const { t } = useMimicTranslation('node');
  const [selectedNode, setSelectedNode] = useState<Node | undefined>();
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { filters, sort, paginationParams, updateQueryParams } = useQueryParams<Node, NodeFilters, NodeSortableFields>(
    NodesFilterTypes,
    undefined,
    { isOperating: true },
  );

  const assignConfigRevision = useAssignConfigRevisionMutation(selectedTenantID!);

  const options = getTagListOptions(selectedTenantID!, filters, ['+name'], paginationParams);
  const { data: tagsList } = useQuery(options);

  const isOperatingFilters = { ...filters, isOperating: true };
  const isPendingFilters = { ...filters, isOperating: false };

  const configuredNodesQuery = useNodesQuery(selectedTenantID!, isOperatingFilters, sort, paginationParams);
  const pendingNodesQuery = useNodesQuery(selectedTenantID!, isPendingFilters, sort, paginationParams);

  const getNodeConfigurationPath = (nodeConfigId: string) =>
    `/tenants/${selectedTenantID}/node-configs/${nodeConfigId}`;

  const onAssignConfiguration = (node: Node) => {
    setSelectedNode(node);
  };

  const onAssign = async (node: Node, configRevision: ConfigRevision) => {
    try {
      await assignConfigRevision.mutateAsync({
        nodeIDs: [node.id],
        configID: configRevision.nodeConfigId!,
        revisionNumber: configRevision.revisionNumber,
      });

      setSelectedNode(undefined);
    } catch (err) {
      message.error(t('feedback.assignNodeConfigError'));
      Sentry.captureException(err);
    }
  };

  const onCancel = () => {
    setSelectedNode(undefined);
  };

  return (
    <>
      <ContentContainer width="100%" padding="xl">
        <NodeListPage
          tenantID={selectedTenantID!}
          tags={tagsList?.data}
          onParamsChange={updateQueryParams}
          filters={filters}
          configuredNodesQuery={configuredNodesQuery}
          pendingNodesQuery={pendingNodesQuery}
          onAssignConfiguration={onAssignConfiguration}
          getNodeConfigurationPath={getNodeConfigurationPath}
        />
      </ContentContainer>
      <ConfigWizardOverlay node={selectedNode} onCancel={onCancel} onAssign={onAssign} />
    </>
  );
}
