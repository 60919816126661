/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Skeleton } from '@/primitives/Skeleton';
import { Text } from '@/primitives/Text';
import { useGetSystemInfo } from '@/v1/utils/hooks/useGetSystemInfo';

export function SystemInfo() {
  const { t } = useMimicTranslation('systemInfo');
  const { data, isFetching } = useGetSystemInfo();

  return (
    <ContentContainer>
      <Container>
        <PageHeader title={t('title')} />
        <Flex gap="xxs" vertical>
          <Text size="md" strong>
            {t('mgmtPlaneVersion')}
          </Text>
          <Skeleton loading={isFetching}>
            <Text copyable mono data-testid="management-plane-release">
              {data?.data.mgmtPlaneRelease}
            </Text>
          </Skeleton>
        </Flex>
      </Container>
    </ContentContainer>
  );
}
