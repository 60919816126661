/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Drawer } from 'antd';

import './ConfigureNodesJobWizard.css';
import { Node } from '@/client';
import {
  setJobWizardJobName,
  setJobWizardSelectedConfigRevisionInfo,
  setJobWizardSelectedNodes,
} from '@/state/jobWizardStore';

import { ConfigureNodesJobWizardHeader } from './ConfigureNodesJobWizardHeader';
import { ConfigureNodesJobWizardReviewJob } from './ConfigureNodesJobWizardReviewJob';
import {
  ConfigRevisionInfo,
  ConfigureNodesJobWizardSelectConfigRevision,
} from './ConfigureNodesJobWizardSelectConfigRevision';
import { ConfigureNodesJobWizardSelectNodes } from './ConfigureNodesJobWizardSelectNodes';
import { ConfigureNodesJobWizardSteps } from './ConfigureNodesJobWizardSteps';

export type ConfigureNodesJobWizardProps = {
  tenantID: string;
  onCloseWizard: () => void;
  currentStep: ConfigureNodesJobWizardSteps;
  selectedNodes: Node[];
  selectedConfigRevisionInfo: ConfigRevisionInfo;
  jobName?: string;
};

export function ConfigureNodesJobWizard({
  tenantID,
  currentStep,
  onCloseWizard,
  selectedNodes,
  selectedConfigRevisionInfo,
  jobName,
}: ConfigureNodesJobWizardProps) {
  const onClose = () => {
    setJobWizardSelectedNodes([]);
    setJobWizardSelectedConfigRevisionInfo({});
    setJobWizardJobName('');
    onCloseWizard();
  };

  const steps = {
    'select-nodes': <ConfigureNodesJobWizardSelectNodes tenantID={tenantID} selectedNodes={selectedNodes} />,
    'select-config': <ConfigureNodesJobWizardSelectConfigRevision tenantID={tenantID} selectedNodes={selectedNodes} />,
    'review-job': (
      <ConfigureNodesJobWizardReviewJob
        tenantID={tenantID}
        jobName={jobName}
        selectedNodes={selectedNodes}
        selectedConfigRevisionInfo={selectedConfigRevisionInfo}
        onSubmit={onClose}
      />
    ),
  };

  return (
    <Drawer
      onClose={onClose}
      className="configure-nodes-job-wizard"
      placement="top"
      open
      height="100vh"
      closeIcon={null}
    >
      <div className="configure-nodes-job-wizard-container">
        <ConfigureNodesJobWizardHeader onClick={onClose} currentStep={currentStep} />
        {steps[currentStep]}
      </div>
    </Drawer>
  );
}
