/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Modal } from 'antd';

import { Tag } from '@/client';
import { useMessage } from '@/hooks/message';
import { useDeleteTagMutation } from '@/hooks/mutation/useDeleteTagMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';

type DeleteTagModalProps = {
  deleteTag: Tag | undefined;
  deleteIsPending: boolean;
  setDeleteIsPending: (isPending: boolean) => void;
  onCancelTagDelete: () => void;
};

export function DeleteTagModal({
  deleteTag,
  deleteIsPending,
  setDeleteIsPending,
  onCancelTagDelete,
}: DeleteTagModalProps) {
  const { t } = useMimicTranslation('tags');

  const [message] = useMessage();

  const { mutate: deleteTagMutation } = useDeleteTagMutation({
    onError: (_, tag) => {
      message.error(t('tagDeleteError', tag));
      setDeleteIsPending(false);
    },
    onSuccess: (_, deletedTag) => {
      message.success(t('tagDeleted', deletedTag));
      closeDeleteDialog();
      setDeleteIsPending(false);
    },
    onMutate: () => {
      setDeleteIsPending(true);
    },
  });

  const onTagDelete = () => {
    deleteTagMutation(deleteTag!);
  };

  const closeDeleteDialog = () => {
    onCancelTagDelete();
  };

  return (
    <Modal
      open={Boolean(deleteTag)}
      onCancel={onCancelTagDelete}
      onOk={onTagDelete}
      cancelText={t('confirmDelete.cancel')}
      cancelButtonProps={{ disabled: deleteIsPending }}
      okText={t('confirmDelete.ok')}
      okButtonProps={{ danger: true, loading: deleteIsPending }}
    >
      <Container padding="sm">
        <Title level={4}>{t('confirmDelete.title', { name: deleteTag?.name })}</Title>
        <Text>{t('confirmDelete.body1', { useCount: deleteTag?.useCount })}</Text>
        <Text>{t('confirmDelete.body2')}</Text>
      </Container>
    </Modal>
  );
}
