/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { SyncOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Space } from 'antd';
import { useState } from 'react';

import { Activity } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Text } from '@/primitives/Text';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { getActivityListOptions } from '@/v1/utils/hooks/getActivityListOptions';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';

import { ActivityListPage } from './ActivityListPage';

export function ActivityRoute() {
  const queryClient = useQueryClient();
  const { t } = useMimicTranslation('activity');
  const [dateUpdated, setDateUpdated] = useState(new Date());
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { paginationParams, updateQueryParams } = useQueryParams<Activity>();

  const activityListOptions = getActivityListOptions(selectedTenantID!, {}, paginationParams);
  const activitiesQuery = useQuery(activityListOptions);

  const updateData = () => {
    setDateUpdated(new Date());
    queryClient.invalidateQueries({ queryKey: ['activities'] });
  };

  return (
    <ContentContainer width="100%">
      <PageHeader
        title={t('title')}
        level={2}
        text={
          <>
            <Text>
              {t('lastUpdated')} <RelativeTime date={dateUpdated} />
            </Text>
            <Space.Compact size="small" style={{ fontSize: 'inherit' }}>
              <Button type="link" onClick={updateData}>
                <SyncOutlined /> {t('actions.refresh')}
              </Button>
            </Space.Compact>
          </>
        }
      />
      <div style={{ paddingTop: '2rem' }}>
        <ActivityListPage
          tenantID={selectedTenantID!}
          activitiesQuery={activitiesQuery}
          onParamsChange={updateQueryParams}
        />
      </div>
    </ContentContainer>
  );
}
