/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  baseSchema,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { TYPE_UUID } from '../../types';

export const tagName = z
  .string()
  .min(1)
  .max(20)
  .openapi({ example: 'staging' });
export const uuid = TYPE_UUID.openapi({
  example: '550e8400-e29b-41d4-a716-446655440000',
});
export const tenantID = uuid;

// base schema
export const BaseTagSchema = z.object({
  id: TYPE_UUID.openapi({
    'x-go-name': 'ID',
    example: '550e8400-e29b-41d4-a716-446655440000',
  }),
  name: tagName,
  tenantID,
  useCount: z.number().int().min(0).openapi({ example: 10 }),
});
export const TagSchema = BaseTagSchema.merge(baseSchema).openapi('Tag');

// mutation schemas
export const CreateTagSchema = z
  .object({
    name: tagName,
  })
  .required({
    name: true,
  })
  .openapi('CreateTag');

export const PatchTagSchema = z
  .object({
    name: tagName.optional(),
  })
  .openapi('PatchTag');

// response schemas
export const TagResponseSchema = buildResponseSchema('TagResponse', TagSchema);

export const TagFilterParamsSchema = z
  .object({
    name: tagName.optional().openapi({ example: 'sta' }),
  })
  .openapi('TagFilters', { example: { name: 'sta' } });

export const sort = sortable('TagSortableFields', ['name', 'useCount']);

export const TagQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: TagFilterParamsSchema.optional(),
    sort: sort.optional(),
  }),
);

export const PaginatedTagsResponseSchema = buildPaginatedResponseSchema(
  'PaginatedTagsResponse',
  TagSchema,
  TagFilterParamsSchema,
  sort,
);
