/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { useState } from 'react';

import { Node, OperationalStateChangeResp, RequestedOperationalState } from '@/client';
import { useMessage } from '@/hooks/message';
import { useAuthorization } from '@/hooks/useAuthorization';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Alert } from '@/primitives/Alert';
import { Flex } from '@/primitives/Flex';
import { NodeViewCard } from '@/primitives/NodeViewCard';
import { Space } from '@/primitives/Space';
import { Switch } from '@/primitives/Switch';
import { Text } from '@/primitives/Text';
import { antdTokens, tokens } from '@/theme';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

import { ConnectivityInfo } from './ConnectivityInfo';
import { DeflectionsInfo } from './DeflectionsInfo';
import { DetectionsInfo } from './DetectionsInfo';
import { IncomingData } from './IncomingData';
import { NodeTransitionIndicator } from './NodeTransitionIndicator';

export type NodeStateProps = {
  isLoading: boolean;
  node: Node;
  mutationConfig: UseMutationOptions<OperationalStateChangeResp, unknown, RequestedOperationalState>;
};

export function NodeState({ isLoading, node, mutationConfig }: NodeStateProps) {
  const { t } = useMimicTranslation('node');
  const { mutateAsync } = useMutation(mutationConfig);
  const [isOperational, setIsOperational] = useState(node.requestedRevisionState?.operationalState === 'active');
  const [message] = useMessage();
  const canManageNodes = useAuthorization('superadmin');

  const interactionsBlocked = node.requestedRevisionState?.status === 'pending';
  const showError = node.requestedRevisionState?.status === 'rejected';

  useSubscribeToEvents<OperationalStateChangeResp>('node:operational-state-changed', (event) => {
    if (!event.entityIds.includes(node.id) || !event.data?.data) return;
    setIsOperational(event.data?.data.operationalState === 'active');
  });

  const onChange: SwitchChangeEventHandler = (checked) => {
    mutateAsync({
      requestedOperationalState: checked ? 'active' : 'idle',
    })
      .then(() => {
        setIsOperational(!isOperational);
        message.success(t('operationalStateChangeRequested'));
      })
      .catch(() => message.error(t('operationalStateChangeFailed')));
  };

  const backgroundColor = interactionsBlocked ? antdTokens.colorBgContainer : undefined;

  return (
    <Space dir="vertical" size="sm" width="100%">
      <NodeViewCard gap="xxs" style={{ backgroundColor }} bodyStyles={{ paddingTop: '24px' }}>
        <Form layout="vertical">
          <Flex justify="space-between">
            <IncomingData
              disabled={interactionsBlocked}
              marginBottom={0}
              labelMarginBottom="12px"
              label={t('operationalState')}
              loading={isLoading}
              bold
            >
              <Switch
                checked={isOperational}
                disabled={!canManageNodes || interactionsBlocked}
                checkedChildren={t('active')}
                unCheckedChildren={<Text type="subtle">{t('idle')}</Text>}
                onChange={onChange}
                dataTestId="operational-state-switch"
                style={{
                  transform: 'scale(1.5)',
                  transformOrigin: 'left',
                  backgroundColor:
                    node.requestedRevisionState?.operationalState === 'active'
                      ? tokens.color.components.switch.switchOnSuccessBg
                      : '',
                }}
              />
            </IncomingData>
            <ConnectivityInfo
              disabled={interactionsBlocked}
              isLoading={isLoading}
              connectivityState={node.connectivityState}
            />
            <DetectionsInfo disabled={interactionsBlocked} isLoading={isLoading} hallmarksCount={node.hallmarksCount} />
            <DeflectionsInfo
              disabled={interactionsBlocked}
              isLoading={isLoading}
              deflectionsCount={node.deflectionsCount}
            />
          </Flex>
        </Form>
        <NodeTransitionIndicator node={node} />
      </NodeViewCard>

      {showError && (
        <Alert
          dataTestId="operational-state-change-failed-alert"
          message={t('operationalStateChangeFailed')}
          description={t('operationalStateChangeFailedDescription', {
            requestedState: t(node.requestedRevisionState!.operationalState),
          })}
          type="error"
          showIcon
        />
      )}
    </Space>
  );
}
