/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';

import { Job, JobFilters, PaginatedJobsResponse } from '@/client';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { UpdateParams } from '@/utils/params';

import { JobListTable } from './JobListTable';

export type JobListProps = {
  tenantID: string;
  jobsQuery: UseQueryResult<PaginatedJobsResponse, Error>;
  jobFilters: JobFilters;
  updateQueryParams: UpdateParams<Job, Partial<JobFilters>>;
};

export function JobList({ tenantID, jobsQuery, updateQueryParams, jobFilters }: JobListProps) {
  const { t } = useMimicTranslation('jobs');
  return (
    <Container full style={{ width: '100%' }}>
      <PageHeader title={t('listTitle')} level={2} />
      <JobListTable
        tenantID={tenantID}
        jobsQuery={jobsQuery}
        updateQueryParams={updateQueryParams}
        jobFilters={jobFilters}
      />
    </Container>
  );
}
