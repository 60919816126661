/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  OpenAPIRegistry,
  OpenApiGeneratorV3,
  RouteConfig,
  ZodRequestBody,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import fs from 'fs';
import {
  UnknownKeysParam,
  ZodArray,
  ZodEnum,
  ZodObject,
  ZodRawShape,
  ZodTypeAny,
  z,
} from 'zod';
import { extendedSchema, oapiDefineExtraTags } from './oapi-extra-tags';
import zodToJsonSchema from 'zod-to-json-schema';
import { version } from './version';

extendZodWithOpenApi(z);

const title = 'Management Plane API';
const description =
  'This is the API specification for the Management Plane API.';

/*
mimicOpenAPIRegister is the registry that will be used to register paths/routes.
*/
export const mimicOpenAPIRegister = new OpenAPIRegistry();

/*
generateDocument() will generate the openapi.json file in the root of the project.
*/
export function generateDocument() {
  const generator = new OpenApiGeneratorV3(mimicOpenAPIRegister.definitions);
  const generateDocument = generator.generateDocument({
    openapi: '3.0.0',
    info: {
      version: version,
      title: title,
      description: description,
    },
  });
  oapiDefineExtraTags(generateDocument);
  fs.writeFileSync('./openapi.json', JSON.stringify(generateDocument, null, 2));

  // generate a go file with the spec info
  const goInfo = `package spec

const (
	Description = "${description}"
	Title       = "${title}"
	Version     = "${version}"
)
`;
  fs.writeFileSync('./spec_info.go', goInfo);
}

/*
httpErrorStatusesDescriptionMap is a map of the HTTP status codes we use and their description.
*/
const httpErrorStatusesDescriptionMap: { [key: number]: string } = {
  401: 'Unauthorized',
  404: 'Not found',
  500: 'Internal server error',
};

/*
Pagination is the schema that is used along with arrays in paginated responses.
*/
const Pagination = z
  .object({
    currentPage: z.number().int().min(1),
    pageSize: z.number().int().min(1),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(0),
  })
  .openapi('Pagination');

export const PaginationParams = z
  .object({
    page: z.number().int().min(1).optional(),
    pageSize: z.number().int().min(1).max(100).optional(),
  })
  .openapi('PaginationParams', { example: { page: 1, pageSize: 10 } });

const buildSortVariations = (sortableFields: string[]) => {
  return sortableFields.reduce((acc, field) => {
    acc.push(`-${field}`);
    acc.push(`+${field}`);
    return acc;
  }, [] as string[]) as [string, ...string[]];
};

export const sortable = (name: string, sortableFields: string[]) => {
  const variations = buildSortVariations(sortableFields);
  const enumSchema = z.enum(variations);
  return z.array(enumSchema).openapi(
    name,
    extendedSchema({
      param: { style: 'form', explode: false },
      'x-oapi-codegen-extra-tags': {
        validate: `required,oneof=${variations.join(' ')}`,
      },
    }),
  );
};

const buildMetaResponseSchema = <T extends ZodRawShape>(
  schemaName: string,
  schema: ZodObject<T, UnknownKeysParam>,
  sortableFields: ZodArray<ZodEnum<[string, ...string[]]>>,
) => {
  return z
    .object({
      page: Pagination,
      filters: schema,
      sort: sortableFields,
    })
    .openapi(schemaName);
};

export const buildPaginatedResponseSchema = <
  T extends ZodRawShape,
  U extends ZodRawShape,
>(
  schemaName: string,
  schema: ZodObject<T, UnknownKeysParam>,
  filtersSchema: ZodObject<U, UnknownKeysParam>,
  sortableFields: ZodArray<ZodEnum<[string, ...string[]]>>,
) => {
  return z
    .object({
      data: z.array(schema),
      meta: buildMetaResponseSchema(
        `${schemaName}Meta`,
        filtersSchema,
        sortableFields,
      ),
    })
    .openapi(schemaName);
};

/*
baseSchema defines some common fields (like createdBy and dateCreated) for most of the schemas.
*/
export const baseSchema = z.object({
  createdBy: z.string().uuid(),
  dateCreated: z.date().openapi({ format: 'date-time' }),
  updatedBy: z.string().uuid(),
  dateUpdated: z.date().openapi({ format: 'date-time' }),
});

const validationErrorSchema = z
  .object({ field: z.string(), message: z.string() })
  .openapi('ValidationError');

const errorResponseSchema = z
  .object({
    status: z.number().int(),
    message: z.string(),
    errors: z.array(validationErrorSchema).optional(),
  })
  .openapi('ErrorResponse');

/*
buildResponseSchema nests the schema inside a data object.
*/
export const buildResponseSchema = <T extends ZodRawShape>(
  schemaName: string,
  schema: ZodObject<T, UnknownKeysParam>,
) => {
  return z.object({ data: schema }).openapi(schemaName);
};

/*
buildResponsesDefinition will define the HTTP status responses for a request.
*/
export const buildResponsesDefinition = (options: {
  successStatus: number;
  successDescription: string;
  successSchema?: ZodTypeAny;
  errorStatuses: number[];
}): RouteConfig['responses'] => {
  // successStatus is required (schema is optional)
  const responses: RouteConfig['responses'] = {
    [options.successStatus]: {
      description: options.successDescription,
      content: options.successSchema
        ? {
            'application/json': {
              schema: options.successSchema,
            },
          }
        : undefined,
    },
  };

  // append error statuses with their description
  options.errorStatuses.forEach((status) => {
    responses[status] = {
      description: httpErrorStatusesDescriptionMap[status] || 'Error',
      content: {
        'application/json': {
          schema: errorResponseSchema,
        },
      },
    };
  });

  return responses;
};

/*
buildRequestBodyDefinition will define a JSON body for a request.
*/
export function buildRequestBodyDefinition<T extends ZodTypeAny>(
  schema: T,
): ZodRequestBody {
  return {
    description: 'Request data',
    required: true,
    content: {
      'application/json': {
        schema,
      },
    },
  };
}

// NOTE that everything below is hard coded instead of using `z` types
// because zod does not support file types properly for upload APIs.  See
// https://stackoverflow.com/questions/77287011/nestjs-swagger-multipart-file-upload-issue-with-zod
export function buildUploadRequestBodyDefinition<T extends ZodTypeAny>(
  zodSchema: T,
): ZodRequestBody {
  // fileProperty: string,
  // _schema: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const jsonSchema = zodToJsonSchema(zodSchema) as any;
  return {
    description: 'Multipart form-data request',
    required: true,
    content: {
      'multipart/form-data': {
        schema: {
          type: 'object',
          properties: {
            file: {
              type: 'string',
              format: 'binary',
            },
            ...jsonSchema.properties,
          },
          required: ['file', ...jsonSchema.required],
        },
      },
    },
  };
}
