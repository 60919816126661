/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';

import { ContentContainer } from '@/components/ContentContainer';
import { Flex } from '@/primitives/Flex';
import { Skeleton } from '@/primitives/Skeleton';
import { HTTPError } from '@/v1/components/HTTPError';
import { NodeConfigurationDetails } from '@/v1/components/NodeConfigurationDetails/NodeConfigurationDetails';
import { useGetNodeConfigQuery } from '@/v1/utils/hooks/useGetNodeConfigQuery';

export function NodeConfigurationDetailsRoute() {
  const { tenantID, nodeConfigId } = useParams<'tenantID' | 'nodeConfigId'>();

  const { isError, error, isPending, data: NodeConfigResponse } = useGetNodeConfigQuery(tenantID!, nodeConfigId!);

  if (!tenantID || !nodeConfigId) {
    Sentry.captureException(
      new Error('Missing tenantId. Are we rendering NodeConfigurationDetials in a route that provides this param?'),
    );

    return <HTTPError httpStatus="500" />;
  }

  if (isPending) return <Skeleton loading={isPending} title width="xxl" />;

  if (isError) {
    switch (error?.status) {
      case 403:
        return <HTTPError httpStatus="403" />;
      case 404:
        return <HTTPError httpStatus="404" />;
      default:
        Sentry.captureException(error);
        return <HTTPError httpStatus="500" />;
    }
  }

  if (!NodeConfigResponse?.data) {
    Sentry.captureException(new Error('Node Config not found while HTTP status is 2xx?'));
    return <HTTPError httpStatus="500" />;
  }

  return (
    <ContentContainer width="100%">
      <Flex vertical gap="sm">
        <NodeConfigurationDetails tenantID={tenantID} nodeConfig={NodeConfigResponse.data} />
      </Flex>
    </ContentContainer>
  );
}
