/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ValidationError } from '@/components/Forms/ValidationError';

export type FormErrors<T> = {
  [key in keyof T]: string;
};

export type ErrorField = {
  name: string;
  errors: string[];
};

export function validationErrors<T>(errors: FormErrors<T>): ErrorField[] {
  return Object.entries<string>(errors).map(([field, message]) => {
    return {
      name: field,
      /* eslint @typescript-eslint/no-explicit-any: "off" */
      errors: [(<ValidationError>{message}</ValidationError>) as any], // Can be ReactNode
    };
  });
}
