/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  sortable,
} from '../../openapi';
import { OPENAPI_NODE_ID, OPENAPI_TENANT_ID } from '../../types';

export const NodeHallmarkSchema = z
  .object({
    tenantID: OPENAPI_TENANT_ID,
    nodeID: OPENAPI_NODE_ID,
    mimicID: z.string(),
    mimicDescription: z.string(),
    occurrences: z.number().int(),
    firstSeen: z.string().datetime(),
    lastSeen: z.string().datetime(),
  })
  .openapi('NodeHallmark');

export const NodeHallmarkFilterParamsSchema = z
  .object({
    mimicID: z.string().optional(),
    mimicDescription: z.string().optional(),
  })
  .openapi('NodeHallmarkFilters');

export const sort = sortable('NodeHallmarkSortableFields', []);

export const NodeHallmarksQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: NodeHallmarkFilterParamsSchema.optional(),
    sort: sort.optional(),
  }),
);

export const PaginatedNodeHallmarksResponseSchema =
  buildPaginatedResponseSchema(
    'PaginatedNodeHallmarks',
    NodeHallmarkSchema,
    NodeHallmarkFilterParamsSchema,
    sort,
  );
