/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ContentContainer } from '@/components/ContentContainer';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { NodeViewPage } from '@/v1/routes/Nodes/NodeViewPage';
import { getNodeOptions } from '@/v1/utils/hooks/getNodeOptions';

export function NodeViewRoute() {
  const params = useParams<'id'>();
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const nodeOptionsQuery = useQuery(getNodeOptions(tenantID!, params.id!));

  if (!params.id) {
    return <span>Invalid node id</span>;
  }

  return (
    <ContentContainer width="100%">
      <NodeViewPage id={params.id} tenantID={tenantID} nodeOptionsQuery={nodeOptionsQuery} />
    </ContentContainer>
  );
}
