/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, FormInstance, Modal } from 'antd';
import { useState } from 'react';

import { ApiError, Tag } from '@/client';
import { validationErrors } from '@/components/Forms/validationErrors';
import { useMessage } from '@/hooks/message';
import { useUpdateTagMutation } from '@/hooks/mutation/useUpdateTagMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { TagForm, TagFormFields } from '@/pages/Tags/TagForm';
import { Container } from '@/primitives/Container';
import { Divider } from '@/primitives/Divider';
import { Title } from '@/primitives/Title';
import { getValidationMessage } from '@/utils/errors';

export type UpdateTagModalProps = {
  editTag: Tag | undefined;
  setEditTag: (tag: Tag | undefined) => void;
  editForm: FormInstance<TagFormFields>;
};

export function UpdateTagModal({ editTag, setEditTag, editForm }: UpdateTagModalProps) {
  const { t } = useMimicTranslation('tags');

  const [message] = useMessage();
  const [updateIsPending, setUpdateIsPending] = useState(false);

  const { mutate: updateTagMutation } = useUpdateTagMutation({
    onError: (error, _) => {
      message.error(t('tagUpdateError', editTag));
      setUpdateIsPending(false);
      if (error instanceof ApiError) {
        const newMessage = getValidationMessage(error, 'name');
        editForm.setFields(validationErrors<TagFormFields>({ name: newMessage }));
      }
    },
    onSuccess: (updatedTag) => {
      message.success(t('tagUpdated', { newName: updatedTag.data.name, oldName: editTag?.name }));
      closeEditDialog();
    },
    onMutate: () => {
      setUpdateIsPending(true);
    },
  });

  const onTagUpdate = async () => {
    if (editTag) {
      try {
        await editForm.validateFields();
      } catch {
        return;
      }
      const name = editForm.getFieldValue('name');
      const oldTag = { ...editTag };
      const newTag = { ...editTag, name };
      updateTagMutation({ id: oldTag.id, requestBody: newTag });
    }
  };

  const closeEditDialog = () => {
    setEditTag(undefined);
    setUpdateIsPending(false);
  };

  const onCancelEditTag = () => {
    closeEditDialog();
  };

  return (
    <Form.Provider onFormFinish={onTagUpdate}>
      <Modal
        key={editTag?.id}
        open={Boolean(editTag)}
        onCancel={onCancelEditTag}
        onOk={onTagUpdate}
        cancelText={t('editTag.cancelButton')}
        cancelButtonProps={{ disabled: updateIsPending }}
        okText={t('editTag.submitButton')}
        okButtonProps={{ loading: updateIsPending, htmlType: 'submit' }}
      >
        <Container gap="lg" full>
          <Title level={4} style={{ marginTop: '32px' }}>
            {t('editTag.title')}
          </Title>
          <TagForm tag={editTag} form={editForm} loading={updateIsPending} />
          <Divider />
        </Container>
      </Modal>
    </Form.Provider>
  );
}
