/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Outlet, useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { HTTPError } from '@/v1/components/HTTPError';

export function NextRoutes() {
  const { tenantId } = useParams<'tenantId'>();

  if (!tenantId || !uuidValidate(tenantId)) {
    return <HTTPError httpStatus="404" />;
  }

  return <Outlet />;
}
