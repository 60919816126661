/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QueryWrapper } from '@/components/QueryWrapper';
import { useMessage } from '@/hooks/message';
import { useUpdateNodeMutation } from '@/hooks/mutation/useUpdateNodeMutation';
import { useNodeByIdQuery } from '@/hooks/query/useNodeByIdQuery';
import { usePaginatedNodeHallmarksQuery } from '@/hooks/query/usePaginatedNodeHallmarksQuery';

export type NodeDetailsPageProps = {
  nodeId: string;
};

export function NodeDetailsPage({ nodeId }: NodeDetailsPageProps) {
  const nodeQuery = useNodeByIdQuery({ nodeId });
  const [message] = useMessage();
  const nodeHallmarksQuery = usePaginatedNodeHallmarksQuery({ params: { nodeId } });
  const { mutate: mutateNode } = useUpdateNodeMutation({
    nodeId,
    onError: (_, variables) => {
      message.error(`Error updating node description with: ${variables.description}`);
    },
  });

  const onClick = () => {
    mutateNode({ description: `Updated description ${Date.now()}` });
  };

  return (
    <>
      <div style={{ border: '1px solid black', width: '50%' }}>
        <QueryWrapper queryResult={nodeQuery}>
          {({ data: node }) => (
            <div>
              <h2>{node.name}</h2>
              <p>
                {node.description}
                <br />
                <button type="button" onClick={onClick}>
                  change description
                </button>
              </p>
            </div>
          )}
        </QueryWrapper>
      </div>
      <div style={{ border: '1px solid black', width: '50%' }}>
        <QueryWrapper queryResult={nodeHallmarksQuery}>
          {({ data: hallmarks }) => (
            <div>
              <h2>Hallmarks</h2>
              <ul>
                {hallmarks.map((hallmark) => (
                  <li key={hallmark.mimicID}>{hallmark.mimicDescription}</li>
                ))}
              </ul>
            </div>
          )}
        </QueryWrapper>
      </div>
    </>
  );
}
