/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';

import { TagResponse, PatchTag, UpdateTagResponse, ApiError, UpdateTagData } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type UpdateTagMutation = PatchTag & { id: string };

export type UpdateTagMutationConfig = {
  onSuccess?: (data: TagResponse, variables: UpdateTagMutation) => void;
  onError?: (error: unknown, variables: UpdateTagMutation) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
  onMutate?: (variables: UpdateTagMutation) => Promise<unknown> | void;
};

/**
 * Hook for updating tag by ID through the management plane API.
 *
 * @param config.onSuccess - Called after successful tag update
 * @param config.onError - Called if update fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for updating tag
 *
 * @example
 * ```tsx
  const { mutate } = useUpdateTagMutation({
 *   onSuccess: (data) => console.log('Tag updated:', data),
 *   onError: (error) => console.error('Tag update failed:', error)
    },
  });

   * // Update Tag
 * mutate({ tagId: '9fb763f7-33de-4969-a7a0-b808f4637318' });
 * ```
 */

export function useUpdateTagMutation({ onSuccess, onError, onSettled, onMutate }: UpdateTagMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const { mutate, isError, error } = useMutation<
    UpdateTagResponse,
    ApiError,
    Pick<UpdateTagData, 'id' | 'requestBody'>
  >({
    mutationFn: ({ id: tagId, requestBody }) => {
      return mgmtPlaneAPIClient.tags.updateTag({
        tenantId: tenantId!,
        id: tagId,
        requestBody: { name: requestBody.name },
      });
    },
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });

  return { mutate, isError, error };
}
