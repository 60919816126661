/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { TagFilterParamsSchema } from 'openapi-spec/api/routes/tags/schemas';

import { GetTagsData, GetTagsResponse, Tag, TagFilters, TagSortableFields } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedTagsQueryParams = {
  params?: Omit<GetTagsData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated tags with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated nodes response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedTagsQuery({ params, type }: UsePaginatedTagsQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<GetTagsResponse, Tag, TagFilters, TagSortableFields, GetTagsData>({
    apiCall: mgmtPlaneAPIClient.tags.getTags.bind(mgmtPlaneAPIClient.tags),
    filterTypes: zodSchemaToTypeMap(TagFilterParamsSchema.shape),
    queryKey: 'tags',
    realtimeEvents: ['tag:created', 'tag:updated', 'tag:deleted', 'node:tag-added', 'node:tag-deleted'],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    },
    type,
  });
}
