import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';

import { Job, JobNode, JobNodeFilters, JobNodeSortableFields, JobStatus } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { UpdateParams } from '@/utils/params';
import { fromSorter } from '@/utils/sort';
import { JobNodeStatus } from '@/v1/components/JobDetails/JobNodeStatus';
import { JobNodeIssuesModal } from '@/v1/components/JobNodeIssuesModal';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { useJobNodesQuery } from '@/v1/utils/hooks/useJobNodesQuery';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

type JobListRowExpandedProps = {
  job: Job;
};

export function JobListRowExpanded({ job }: JobListRowExpandedProps) {
  const { t } = useMimicTranslation('jobs');
  const queryClient = useQueryClient();

  const [showWarningsModal, setShowWarningsModal] = useState<boolean>(false);
  const [warningsModalContents, setWarningsModalContent] = useState<JobNode['warnings']>();
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [errorsModalContents, setErrorsModalContent] = useState<JobNode['errors']>();
  const [jobNodeFilters, setJobNodeFilters] = useState<Partial<JobNodeFilters>>({});
  const [nodeConfigSort, setNodeConfigSort] = useState<JobNodeSortableFields>(['+hostname']);
  const [nodeConfigPage, setNodeConfigPage] = useState<PaginationParams>({ number: 1, size: 10 });

  useSubscribeToEvents(
    ['node:operational-state-changed', 'node-config:assigned', 'job:updated', 'job:completed', 'job:timedout'],
    () => {
      queryClient.invalidateQueries({ queryKey: ['job-nodes', job.id] });
    },
  );

  const jobNodesQuery = useJobNodesQuery({
    jobId: job.id,
    tenantId: job.tenantId,
    filters: jobNodeFilters,
    sort: nodeConfigSort,
    settings: nodeConfigPage,
  });

  const updateQueryParams: UpdateParams<JobNode> = ({ filters, sort, paginationParams }) => {
    if (filters) setJobNodeFilters(filters);
    if (paginationParams) setNodeConfigPage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<JobNodeSortableFields, JobNode>(sort);
      setNodeConfigSort(sortFields);
    }
  };

  const openWarningsModalWithDetails = (details: JobNode['warnings']) => {
    setWarningsModalContent(details);
    setShowWarningsModal(true);
  };

  const closeWarningsModal = () => {
    setShowWarningsModal(false);
    setWarningsModalContent(undefined);
  };

  const openErrorsModalWithDetails = (details: JobNode['errors']) => {
    setErrorsModalContent(details);
    setShowErrorsModal(true);
  };

  const closeErrorsModal = () => {
    setShowErrorsModal(false);
    setErrorsModalContent(undefined);
  };

  const columns = getJobNodesColumn(job, t, openWarningsModalWithDetails, openErrorsModalWithDetails);

  return (
    <>
      <PaginatedTable
        paginatedResource={jobNodesQuery}
        columns={columns}
        rowKey={(config) => config.id}
        data-testid="configuration-table"
        onParamsChange={updateQueryParams}
      />
      <JobNodeIssuesModal
        type="warnings"
        showModal={showWarningsModal}
        closeModal={closeWarningsModal}
        modalContents={warningsModalContents}
      />
      <JobNodeIssuesModal
        type="errors"
        showModal={showErrorsModal}
        closeModal={closeErrorsModal}
        modalContents={errorsModalContents}
      />
    </>
  );
}

function getJobNodesColumn(
  job: Job,
  t: MimicTranslationFunction<'jobs'>,
  openWarningsModalWithDetails: (details: JobNode['warnings']) => void,
  openErrorsModalWithDetails: (details: JobNode['errors']) => void,
) {
  return [
    {
      title: <span data-testid="hostName">{t('jobNodes.hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, jobNode: JobNode) => {
        return (
          <Link
            to={`/tenants/${job.tenantId}/nodes/${jobNode.nodeId}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Text type="link" size="md">
              {hostname}
            </Text>
          </Link>
        );
      },
    },
    {
      title: (
        <span data-testid="nodeVersion">
          {t('jobNodes.nodeVersion', { startDate: `${dayjs(job.dateCreated).format('D MMM YYYY')}` })}
        </span>
      ),
      dataIndex: 'nodeVersion',
      sorter: true,
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="status">{t('jobNodes.status')}</span>,
      dataIndex: 'status',
      sorter: true,
      render: (status: JobStatus, jobNode: JobNode) => {
        const showWarningsButton = !!jobNode.warnings;
        const openWarningsModal = () => {
          openWarningsModalWithDetails(jobNode.warnings);
        };
        const openErrorsModal = () => {
          openErrorsModalWithDetails(jobNode.errors);
        };
        return (
          <JobNodeStatus
            status={status}
            openWarningsModal={openWarningsModal}
            openErrorsModal={openErrorsModal}
            showWarningsButton={showWarningsButton}
          />
        );
      },
    },
  ];
}
