/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { PaginatedTagsResponse, Tag, TagFilters } from '@/client';
import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { PaginatedTable } from '@/components/PaginatedTable';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { DeleteTagModal } from '@/pages/Tags/DeleteTagModal';
import { NewTagDialog } from '@/pages/Tags/NewTagDialog';
import { TagFormFields } from '@/pages/Tags/TagForm';
import { UpdateTagModal } from '@/pages/Tags/UpdateTagModal';
import { useTagListColumns } from '@/pages/Tags/useTagListColumns';
import { Container } from '@/primitives/Container';
import { Dropdown } from '@/primitives/Dropdown';
import { UpdateParams } from '@/utils/params';

export type TagsListPageProps = {
  tagsData: PaginatedTagsResponse;
  onParamsChange: UpdateParams<Tag, TagFilters>;
};

export function TagsList({ tagsData, onParamsChange }: TagsListPageProps) {
  const { t } = useMimicTranslation('tags');

  const [deleteTag, setDeleteTag] = useState<Tag>();
  const [editTag, setEditTag] = useState<Tag>();
  const [newTagOpen, setNewTagOpen] = useState<boolean>();
  const [deleteIsPending, setDeleteIsPending] = useState(false);
  const [editForm] = Form.useForm<TagFormFields>();

  useEffect(() => {
    if (editTag) editForm.resetFields();
  }, [editForm, editTag]);

  const closeDeleteDialog = () => {
    setDeleteTag(undefined);
    setDeleteIsPending(false);
  };

  const onNewTagOpenChange = () => {
    if (newTagOpen) {
      // Allows background clicks to close dropdown
      setNewTagOpen(undefined);
    }
  };

  const newTagDialog = () => <NewTagDialog setNewTagOpen={setNewTagOpen} />;

  const searchText = tagsData.meta.filters.name || '';
  const columns = useTagListColumns(searchText, setEditTag, setDeleteTag);

  return (
    <Container>
      <PageHeader title={t('title')} text={t('subtitle')} level={2}>
        <Container horizontal gap="sm">
          <Dropdown open={newTagOpen} onOpenChange={onNewTagOpenChange} customDialog={newTagDialog}>
            <GuardedButton
              requiredRole="editor"
              type="primary"
              dataTestId="new-tag"
              onClick={() => setNewTagOpen(!newTagOpen)}
            >
              <PlusOutlined /> {t('newTag')}
            </GuardedButton>
          </Dropdown>
        </Container>
      </PageHeader>
      <PaginatedTable data={tagsData} rowKey={(tag) => tag.id} columns={columns} onParamsChange={onParamsChange} />

      <DeleteTagModal
        deleteTag={deleteTag}
        deleteIsPending={deleteIsPending}
        setDeleteIsPending={setDeleteIsPending}
        onCancelTagDelete={closeDeleteDialog}
      />

      <UpdateTagModal editTag={editTag} setEditTag={setEditTag} editForm={editForm} />
    </Container>
  );
}
